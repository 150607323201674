/**
 * @fileoverview gRPC-Web generated client stub for healthApi
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.19.1
// source: health.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.healthApi = require('./health_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.healthApi.HealthProtoServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.healthApi.HealthProtoServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.healthApi.ListAppointmentRequest,
 *   !proto.healthApi.ListAppointmentResponse>}
 */
const methodDescriptor_HealthProtoService_ListAppointment = new grpc.web.MethodDescriptor(
  '/healthApi.HealthProtoService/ListAppointment',
  grpc.web.MethodType.UNARY,
  proto.healthApi.ListAppointmentRequest,
  proto.healthApi.ListAppointmentResponse,
  /**
   * @param {!proto.healthApi.ListAppointmentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.healthApi.ListAppointmentResponse.deserializeBinary
);


/**
 * @param {!proto.healthApi.ListAppointmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.healthApi.ListAppointmentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.healthApi.ListAppointmentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.healthApi.HealthProtoServiceClient.prototype.listAppointment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/healthApi.HealthProtoService/ListAppointment',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_ListAppointment,
      callback);
};


/**
 * @param {!proto.healthApi.ListAppointmentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.healthApi.ListAppointmentResponse>}
 *     Promise that resolves to the response
 */
proto.healthApi.HealthProtoServicePromiseClient.prototype.listAppointment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/healthApi.HealthProtoService/ListAppointment',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_ListAppointment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.healthApi.EmptyRequest,
 *   !proto.healthApi.ListAppointmentHistoryResponse>}
 */
const methodDescriptor_HealthProtoService_ListAppointmentHistory = new grpc.web.MethodDescriptor(
  '/healthApi.HealthProtoService/ListAppointmentHistory',
  grpc.web.MethodType.UNARY,
  proto.healthApi.EmptyRequest,
  proto.healthApi.ListAppointmentHistoryResponse,
  /**
   * @param {!proto.healthApi.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.healthApi.ListAppointmentHistoryResponse.deserializeBinary
);


/**
 * @param {!proto.healthApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.healthApi.ListAppointmentHistoryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.healthApi.ListAppointmentHistoryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.healthApi.HealthProtoServiceClient.prototype.listAppointmentHistory =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/healthApi.HealthProtoService/ListAppointmentHistory',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_ListAppointmentHistory,
      callback);
};


/**
 * @param {!proto.healthApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.healthApi.ListAppointmentHistoryResponse>}
 *     Promise that resolves to the response
 */
proto.healthApi.HealthProtoServicePromiseClient.prototype.listAppointmentHistory =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/healthApi.HealthProtoService/ListAppointmentHistory',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_ListAppointmentHistory);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.healthApi.EmptyRequest,
 *   !proto.healthApi.ListAnnotationsResponse>}
 */
const methodDescriptor_HealthProtoService_ListAnnotations = new grpc.web.MethodDescriptor(
  '/healthApi.HealthProtoService/ListAnnotations',
  grpc.web.MethodType.UNARY,
  proto.healthApi.EmptyRequest,
  proto.healthApi.ListAnnotationsResponse,
  /**
   * @param {!proto.healthApi.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.healthApi.ListAnnotationsResponse.deserializeBinary
);


/**
 * @param {!proto.healthApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.healthApi.ListAnnotationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.healthApi.ListAnnotationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.healthApi.HealthProtoServiceClient.prototype.listAnnotations =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/healthApi.HealthProtoService/ListAnnotations',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_ListAnnotations,
      callback);
};


/**
 * @param {!proto.healthApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.healthApi.ListAnnotationsResponse>}
 *     Promise that resolves to the response
 */
proto.healthApi.HealthProtoServicePromiseClient.prototype.listAnnotations =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/healthApi.HealthProtoService/ListAnnotations',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_ListAnnotations);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.healthApi.EmptyRequest,
 *   !proto.healthApi.ListDrugAllergiesResponse>}
 */
const methodDescriptor_HealthProtoService_ListDrugAllergies = new grpc.web.MethodDescriptor(
  '/healthApi.HealthProtoService/ListDrugAllergies',
  grpc.web.MethodType.UNARY,
  proto.healthApi.EmptyRequest,
  proto.healthApi.ListDrugAllergiesResponse,
  /**
   * @param {!proto.healthApi.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.healthApi.ListDrugAllergiesResponse.deserializeBinary
);


/**
 * @param {!proto.healthApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.healthApi.ListDrugAllergiesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.healthApi.ListDrugAllergiesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.healthApi.HealthProtoServiceClient.prototype.listDrugAllergies =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/healthApi.HealthProtoService/ListDrugAllergies',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_ListDrugAllergies,
      callback);
};


/**
 * @param {!proto.healthApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.healthApi.ListDrugAllergiesResponse>}
 *     Promise that resolves to the response
 */
proto.healthApi.HealthProtoServicePromiseClient.prototype.listDrugAllergies =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/healthApi.HealthProtoService/ListDrugAllergies',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_ListDrugAllergies);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.healthApi.EmptyRequest,
 *   !proto.healthApi.ListDrugsResponse>}
 */
const methodDescriptor_HealthProtoService_ListDrugs = new grpc.web.MethodDescriptor(
  '/healthApi.HealthProtoService/ListDrugs',
  grpc.web.MethodType.UNARY,
  proto.healthApi.EmptyRequest,
  proto.healthApi.ListDrugsResponse,
  /**
   * @param {!proto.healthApi.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.healthApi.ListDrugsResponse.deserializeBinary
);


/**
 * @param {!proto.healthApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.healthApi.ListDrugsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.healthApi.ListDrugsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.healthApi.HealthProtoServiceClient.prototype.listDrugs =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/healthApi.HealthProtoService/ListDrugs',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_ListDrugs,
      callback);
};


/**
 * @param {!proto.healthApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.healthApi.ListDrugsResponse>}
 *     Promise that resolves to the response
 */
proto.healthApi.HealthProtoServicePromiseClient.prototype.listDrugs =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/healthApi.HealthProtoService/ListDrugs',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_ListDrugs);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.healthApi.SearchListDrugsRequest,
 *   !proto.healthApi.SearchListDrugsResponse>}
 */
const methodDescriptor_HealthProtoService_SearchListDrugs = new grpc.web.MethodDescriptor(
  '/healthApi.HealthProtoService/SearchListDrugs',
  grpc.web.MethodType.UNARY,
  proto.healthApi.SearchListDrugsRequest,
  proto.healthApi.SearchListDrugsResponse,
  /**
   * @param {!proto.healthApi.SearchListDrugsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.healthApi.SearchListDrugsResponse.deserializeBinary
);


/**
 * @param {!proto.healthApi.SearchListDrugsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.healthApi.SearchListDrugsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.healthApi.SearchListDrugsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.healthApi.HealthProtoServiceClient.prototype.searchListDrugs =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/healthApi.HealthProtoService/SearchListDrugs',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_SearchListDrugs,
      callback);
};


/**
 * @param {!proto.healthApi.SearchListDrugsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.healthApi.SearchListDrugsResponse>}
 *     Promise that resolves to the response
 */
proto.healthApi.HealthProtoServicePromiseClient.prototype.searchListDrugs =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/healthApi.HealthProtoService/SearchListDrugs',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_SearchListDrugs);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.healthApi.EmptyRequest,
 *   !proto.healthApi.ListLifestylesResponse>}
 */
const methodDescriptor_HealthProtoService_ListLifestyles = new grpc.web.MethodDescriptor(
  '/healthApi.HealthProtoService/ListLifestyles',
  grpc.web.MethodType.UNARY,
  proto.healthApi.EmptyRequest,
  proto.healthApi.ListLifestylesResponse,
  /**
   * @param {!proto.healthApi.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.healthApi.ListLifestylesResponse.deserializeBinary
);


/**
 * @param {!proto.healthApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.healthApi.ListLifestylesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.healthApi.ListLifestylesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.healthApi.HealthProtoServiceClient.prototype.listLifestyles =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/healthApi.HealthProtoService/ListLifestyles',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_ListLifestyles,
      callback);
};


/**
 * @param {!proto.healthApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.healthApi.ListLifestylesResponse>}
 *     Promise that resolves to the response
 */
proto.healthApi.HealthProtoServicePromiseClient.prototype.listLifestyles =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/healthApi.HealthProtoService/ListLifestyles',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_ListLifestyles);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.healthApi.SearchDrugAllergiesRequest,
 *   !proto.healthApi.SearchDrugAllergiesResponse>}
 */
const methodDescriptor_HealthProtoService_SearchDrugAllergies = new grpc.web.MethodDescriptor(
  '/healthApi.HealthProtoService/SearchDrugAllergies',
  grpc.web.MethodType.UNARY,
  proto.healthApi.SearchDrugAllergiesRequest,
  proto.healthApi.SearchDrugAllergiesResponse,
  /**
   * @param {!proto.healthApi.SearchDrugAllergiesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.healthApi.SearchDrugAllergiesResponse.deserializeBinary
);


/**
 * @param {!proto.healthApi.SearchDrugAllergiesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.healthApi.SearchDrugAllergiesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.healthApi.SearchDrugAllergiesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.healthApi.HealthProtoServiceClient.prototype.searchDrugAllergies =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/healthApi.HealthProtoService/SearchDrugAllergies',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_SearchDrugAllergies,
      callback);
};


/**
 * @param {!proto.healthApi.SearchDrugAllergiesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.healthApi.SearchDrugAllergiesResponse>}
 *     Promise that resolves to the response
 */
proto.healthApi.HealthProtoServicePromiseClient.prototype.searchDrugAllergies =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/healthApi.HealthProtoService/SearchDrugAllergies',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_SearchDrugAllergies);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.healthApi.EmptyRequest,
 *   !proto.healthApi.ListExamHistoryResponse>}
 */
const methodDescriptor_HealthProtoService_ListExamHistory = new grpc.web.MethodDescriptor(
  '/healthApi.HealthProtoService/ListExamHistory',
  grpc.web.MethodType.UNARY,
  proto.healthApi.EmptyRequest,
  proto.healthApi.ListExamHistoryResponse,
  /**
   * @param {!proto.healthApi.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.healthApi.ListExamHistoryResponse.deserializeBinary
);


/**
 * @param {!proto.healthApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.healthApi.ListExamHistoryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.healthApi.ListExamHistoryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.healthApi.HealthProtoServiceClient.prototype.listExamHistory =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/healthApi.HealthProtoService/ListExamHistory',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_ListExamHistory,
      callback);
};


/**
 * @param {!proto.healthApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.healthApi.ListExamHistoryResponse>}
 *     Promise that resolves to the response
 */
proto.healthApi.HealthProtoServicePromiseClient.prototype.listExamHistory =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/healthApi.HealthProtoService/ListExamHistory',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_ListExamHistory);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.healthApi.ListExamRequest,
 *   !proto.healthApi.ListExamResponse>}
 */
const methodDescriptor_HealthProtoService_ListExam = new grpc.web.MethodDescriptor(
  '/healthApi.HealthProtoService/ListExam',
  grpc.web.MethodType.UNARY,
  proto.healthApi.ListExamRequest,
  proto.healthApi.ListExamResponse,
  /**
   * @param {!proto.healthApi.ListExamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.healthApi.ListExamResponse.deserializeBinary
);


/**
 * @param {!proto.healthApi.ListExamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.healthApi.ListExamResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.healthApi.ListExamResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.healthApi.HealthProtoServiceClient.prototype.listExam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/healthApi.HealthProtoService/ListExam',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_ListExam,
      callback);
};


/**
 * @param {!proto.healthApi.ListExamRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.healthApi.ListExamResponse>}
 *     Promise that resolves to the response
 */
proto.healthApi.HealthProtoServicePromiseClient.prototype.listExam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/healthApi.HealthProtoService/ListExam',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_ListExam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.healthApi.ListRequisitionRequest,
 *   !proto.healthApi.ListRequisitionResponse>}
 */
const methodDescriptor_HealthProtoService_ListRequisition = new grpc.web.MethodDescriptor(
  '/healthApi.HealthProtoService/ListRequisition',
  grpc.web.MethodType.UNARY,
  proto.healthApi.ListRequisitionRequest,
  proto.healthApi.ListRequisitionResponse,
  /**
   * @param {!proto.healthApi.ListRequisitionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.healthApi.ListRequisitionResponse.deserializeBinary
);


/**
 * @param {!proto.healthApi.ListRequisitionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.healthApi.ListRequisitionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.healthApi.ListRequisitionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.healthApi.HealthProtoServiceClient.prototype.listRequisition =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/healthApi.HealthProtoService/ListRequisition',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_ListRequisition,
      callback);
};


/**
 * @param {!proto.healthApi.ListRequisitionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.healthApi.ListRequisitionResponse>}
 *     Promise that resolves to the response
 */
proto.healthApi.HealthProtoServicePromiseClient.prototype.listRequisition =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/healthApi.HealthProtoService/ListRequisition',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_ListRequisition);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.healthApi.SearchListExamHistoryRequest,
 *   !proto.healthApi.SearchListExamHistoryResponse>}
 */
const methodDescriptor_HealthProtoService_SearchListExamHistory = new grpc.web.MethodDescriptor(
  '/healthApi.HealthProtoService/SearchListExamHistory',
  grpc.web.MethodType.UNARY,
  proto.healthApi.SearchListExamHistoryRequest,
  proto.healthApi.SearchListExamHistoryResponse,
  /**
   * @param {!proto.healthApi.SearchListExamHistoryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.healthApi.SearchListExamHistoryResponse.deserializeBinary
);


/**
 * @param {!proto.healthApi.SearchListExamHistoryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.healthApi.SearchListExamHistoryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.healthApi.SearchListExamHistoryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.healthApi.HealthProtoServiceClient.prototype.searchListExamHistory =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/healthApi.HealthProtoService/SearchListExamHistory',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_SearchListExamHistory,
      callback);
};


/**
 * @param {!proto.healthApi.SearchListExamHistoryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.healthApi.SearchListExamHistoryResponse>}
 *     Promise that resolves to the response
 */
proto.healthApi.HealthProtoServicePromiseClient.prototype.searchListExamHistory =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/healthApi.HealthProtoService/SearchListExamHistory',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_SearchListExamHistory);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.healthApi.SearchListLifestylesRequest,
 *   !proto.healthApi.SearchListLifestylesResponse>}
 */
const methodDescriptor_HealthProtoService_SearchListLifestyles = new grpc.web.MethodDescriptor(
  '/healthApi.HealthProtoService/SearchListLifestyles',
  grpc.web.MethodType.UNARY,
  proto.healthApi.SearchListLifestylesRequest,
  proto.healthApi.SearchListLifestylesResponse,
  /**
   * @param {!proto.healthApi.SearchListLifestylesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.healthApi.SearchListLifestylesResponse.deserializeBinary
);


/**
 * @param {!proto.healthApi.SearchListLifestylesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.healthApi.SearchListLifestylesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.healthApi.SearchListLifestylesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.healthApi.HealthProtoServiceClient.prototype.searchListLifestyles =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/healthApi.HealthProtoService/SearchListLifestyles',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_SearchListLifestyles,
      callback);
};


/**
 * @param {!proto.healthApi.SearchListLifestylesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.healthApi.SearchListLifestylesResponse>}
 *     Promise that resolves to the response
 */
proto.healthApi.HealthProtoServicePromiseClient.prototype.searchListLifestyles =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/healthApi.HealthProtoService/SearchListLifestyles',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_SearchListLifestyles);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.healthApi.EmptyRequest,
 *   !proto.healthApi.GetDrugAllergiesResponse>}
 */
const methodDescriptor_HealthProtoService_GetDrugAllergies = new grpc.web.MethodDescriptor(
  '/healthApi.HealthProtoService/GetDrugAllergies',
  grpc.web.MethodType.UNARY,
  proto.healthApi.EmptyRequest,
  proto.healthApi.GetDrugAllergiesResponse,
  /**
   * @param {!proto.healthApi.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.healthApi.GetDrugAllergiesResponse.deserializeBinary
);


/**
 * @param {!proto.healthApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.healthApi.GetDrugAllergiesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.healthApi.GetDrugAllergiesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.healthApi.HealthProtoServiceClient.prototype.getDrugAllergies =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/healthApi.HealthProtoService/GetDrugAllergies',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_GetDrugAllergies,
      callback);
};


/**
 * @param {!proto.healthApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.healthApi.GetDrugAllergiesResponse>}
 *     Promise that resolves to the response
 */
proto.healthApi.HealthProtoServicePromiseClient.prototype.getDrugAllergies =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/healthApi.HealthProtoService/GetDrugAllergies',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_GetDrugAllergies);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.healthApi.VerifyAllergyRequest,
 *   !proto.healthApi.VerifyAllergyResponse>}
 */
const methodDescriptor_HealthProtoService_VerifyAllergy = new grpc.web.MethodDescriptor(
  '/healthApi.HealthProtoService/VerifyAllergy',
  grpc.web.MethodType.UNARY,
  proto.healthApi.VerifyAllergyRequest,
  proto.healthApi.VerifyAllergyResponse,
  /**
   * @param {!proto.healthApi.VerifyAllergyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.healthApi.VerifyAllergyResponse.deserializeBinary
);


/**
 * @param {!proto.healthApi.VerifyAllergyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.healthApi.VerifyAllergyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.healthApi.VerifyAllergyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.healthApi.HealthProtoServiceClient.prototype.verifyAllergy =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/healthApi.HealthProtoService/VerifyAllergy',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_VerifyAllergy,
      callback);
};


/**
 * @param {!proto.healthApi.VerifyAllergyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.healthApi.VerifyAllergyResponse>}
 *     Promise that resolves to the response
 */
proto.healthApi.HealthProtoServicePromiseClient.prototype.verifyAllergy =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/healthApi.HealthProtoService/VerifyAllergy',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_VerifyAllergy);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.healthApi.EmptyRequest,
 *   !proto.healthApi.ListHealthInformationResponse>}
 */
const methodDescriptor_HealthProtoService_ListHealthInformation = new grpc.web.MethodDescriptor(
  '/healthApi.HealthProtoService/ListHealthInformation',
  grpc.web.MethodType.UNARY,
  proto.healthApi.EmptyRequest,
  proto.healthApi.ListHealthInformationResponse,
  /**
   * @param {!proto.healthApi.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.healthApi.ListHealthInformationResponse.deserializeBinary
);


/**
 * @param {!proto.healthApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.healthApi.ListHealthInformationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.healthApi.ListHealthInformationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.healthApi.HealthProtoServiceClient.prototype.listHealthInformation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/healthApi.HealthProtoService/ListHealthInformation',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_ListHealthInformation,
      callback);
};


/**
 * @param {!proto.healthApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.healthApi.ListHealthInformationResponse>}
 *     Promise that resolves to the response
 */
proto.healthApi.HealthProtoServicePromiseClient.prototype.listHealthInformation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/healthApi.HealthProtoService/ListHealthInformation',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_ListHealthInformation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.healthApi.EmptyRequest,
 *   !proto.healthApi.ListProfessionalHomeResponse>}
 */
const methodDescriptor_HealthProtoService_ListProfessionalHome = new grpc.web.MethodDescriptor(
  '/healthApi.HealthProtoService/ListProfessionalHome',
  grpc.web.MethodType.UNARY,
  proto.healthApi.EmptyRequest,
  proto.healthApi.ListProfessionalHomeResponse,
  /**
   * @param {!proto.healthApi.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.healthApi.ListProfessionalHomeResponse.deserializeBinary
);


/**
 * @param {!proto.healthApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.healthApi.ListProfessionalHomeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.healthApi.ListProfessionalHomeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.healthApi.HealthProtoServiceClient.prototype.listProfessionalHome =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/healthApi.HealthProtoService/ListProfessionalHome',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_ListProfessionalHome,
      callback);
};


/**
 * @param {!proto.healthApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.healthApi.ListProfessionalHomeResponse>}
 *     Promise that resolves to the response
 */
proto.healthApi.HealthProtoServicePromiseClient.prototype.listProfessionalHome =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/healthApi.HealthProtoService/ListProfessionalHome',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_ListProfessionalHome);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.healthApi.CreateDrugAllergyRequest,
 *   !proto.healthApi.CreateDrugAllergyResponse>}
 */
const methodDescriptor_HealthProtoService_CreateDrugAllergy = new grpc.web.MethodDescriptor(
  '/healthApi.HealthProtoService/CreateDrugAllergy',
  grpc.web.MethodType.UNARY,
  proto.healthApi.CreateDrugAllergyRequest,
  proto.healthApi.CreateDrugAllergyResponse,
  /**
   * @param {!proto.healthApi.CreateDrugAllergyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.healthApi.CreateDrugAllergyResponse.deserializeBinary
);


/**
 * @param {!proto.healthApi.CreateDrugAllergyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.healthApi.CreateDrugAllergyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.healthApi.CreateDrugAllergyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.healthApi.HealthProtoServiceClient.prototype.createDrugAllergy =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/healthApi.HealthProtoService/CreateDrugAllergy',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_CreateDrugAllergy,
      callback);
};


/**
 * @param {!proto.healthApi.CreateDrugAllergyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.healthApi.CreateDrugAllergyResponse>}
 *     Promise that resolves to the response
 */
proto.healthApi.HealthProtoServicePromiseClient.prototype.createDrugAllergy =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/healthApi.HealthProtoService/CreateDrugAllergy',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_CreateDrugAllergy);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.healthApi.DeleteDrugAllergyRequest,
 *   !proto.healthApi.DeleteDrugAllergyResponse>}
 */
const methodDescriptor_HealthProtoService_DeleteDrugAllergy = new grpc.web.MethodDescriptor(
  '/healthApi.HealthProtoService/DeleteDrugAllergy',
  grpc.web.MethodType.UNARY,
  proto.healthApi.DeleteDrugAllergyRequest,
  proto.healthApi.DeleteDrugAllergyResponse,
  /**
   * @param {!proto.healthApi.DeleteDrugAllergyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.healthApi.DeleteDrugAllergyResponse.deserializeBinary
);


/**
 * @param {!proto.healthApi.DeleteDrugAllergyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.healthApi.DeleteDrugAllergyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.healthApi.DeleteDrugAllergyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.healthApi.HealthProtoServiceClient.prototype.deleteDrugAllergy =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/healthApi.HealthProtoService/DeleteDrugAllergy',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_DeleteDrugAllergy,
      callback);
};


/**
 * @param {!proto.healthApi.DeleteDrugAllergyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.healthApi.DeleteDrugAllergyResponse>}
 *     Promise that resolves to the response
 */
proto.healthApi.HealthProtoServicePromiseClient.prototype.deleteDrugAllergy =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/healthApi.HealthProtoService/DeleteDrugAllergy',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_DeleteDrugAllergy);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.healthApi.UpdateDrugAllergyRequest,
 *   !proto.healthApi.UpdateDrugAllergyResponse>}
 */
const methodDescriptor_HealthProtoService_UpdateDrugAllergy = new grpc.web.MethodDescriptor(
  '/healthApi.HealthProtoService/UpdateDrugAllergy',
  grpc.web.MethodType.UNARY,
  proto.healthApi.UpdateDrugAllergyRequest,
  proto.healthApi.UpdateDrugAllergyResponse,
  /**
   * @param {!proto.healthApi.UpdateDrugAllergyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.healthApi.UpdateDrugAllergyResponse.deserializeBinary
);


/**
 * @param {!proto.healthApi.UpdateDrugAllergyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.healthApi.UpdateDrugAllergyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.healthApi.UpdateDrugAllergyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.healthApi.HealthProtoServiceClient.prototype.updateDrugAllergy =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/healthApi.HealthProtoService/UpdateDrugAllergy',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_UpdateDrugAllergy,
      callback);
};


/**
 * @param {!proto.healthApi.UpdateDrugAllergyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.healthApi.UpdateDrugAllergyResponse>}
 *     Promise that resolves to the response
 */
proto.healthApi.HealthProtoServicePromiseClient.prototype.updateDrugAllergy =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/healthApi.HealthProtoService/UpdateDrugAllergy',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_UpdateDrugAllergy);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.healthApi.CreateAnnotationRequest,
 *   !proto.healthApi.CreateAnnotationResponse>}
 */
const methodDescriptor_HealthProtoService_CreateAnnotation = new grpc.web.MethodDescriptor(
  '/healthApi.HealthProtoService/CreateAnnotation',
  grpc.web.MethodType.UNARY,
  proto.healthApi.CreateAnnotationRequest,
  proto.healthApi.CreateAnnotationResponse,
  /**
   * @param {!proto.healthApi.CreateAnnotationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.healthApi.CreateAnnotationResponse.deserializeBinary
);


/**
 * @param {!proto.healthApi.CreateAnnotationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.healthApi.CreateAnnotationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.healthApi.CreateAnnotationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.healthApi.HealthProtoServiceClient.prototype.createAnnotation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/healthApi.HealthProtoService/CreateAnnotation',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_CreateAnnotation,
      callback);
};


/**
 * @param {!proto.healthApi.CreateAnnotationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.healthApi.CreateAnnotationResponse>}
 *     Promise that resolves to the response
 */
proto.healthApi.HealthProtoServicePromiseClient.prototype.createAnnotation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/healthApi.HealthProtoService/CreateAnnotation',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_CreateAnnotation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.healthApi.UpdateAnnotationRequest,
 *   !proto.healthApi.UpdateAnnotationResponse>}
 */
const methodDescriptor_HealthProtoService_UpdateAnnotation = new grpc.web.MethodDescriptor(
  '/healthApi.HealthProtoService/UpdateAnnotation',
  grpc.web.MethodType.UNARY,
  proto.healthApi.UpdateAnnotationRequest,
  proto.healthApi.UpdateAnnotationResponse,
  /**
   * @param {!proto.healthApi.UpdateAnnotationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.healthApi.UpdateAnnotationResponse.deserializeBinary
);


/**
 * @param {!proto.healthApi.UpdateAnnotationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.healthApi.UpdateAnnotationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.healthApi.UpdateAnnotationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.healthApi.HealthProtoServiceClient.prototype.updateAnnotation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/healthApi.HealthProtoService/UpdateAnnotation',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_UpdateAnnotation,
      callback);
};


/**
 * @param {!proto.healthApi.UpdateAnnotationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.healthApi.UpdateAnnotationResponse>}
 *     Promise that resolves to the response
 */
proto.healthApi.HealthProtoServicePromiseClient.prototype.updateAnnotation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/healthApi.HealthProtoService/UpdateAnnotation',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_UpdateAnnotation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.healthApi.DeleteAnnotationRequest,
 *   !proto.healthApi.DeleteAnnotationResponse>}
 */
const methodDescriptor_HealthProtoService_DeleteAnnotation = new grpc.web.MethodDescriptor(
  '/healthApi.HealthProtoService/DeleteAnnotation',
  grpc.web.MethodType.UNARY,
  proto.healthApi.DeleteAnnotationRequest,
  proto.healthApi.DeleteAnnotationResponse,
  /**
   * @param {!proto.healthApi.DeleteAnnotationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.healthApi.DeleteAnnotationResponse.deserializeBinary
);


/**
 * @param {!proto.healthApi.DeleteAnnotationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.healthApi.DeleteAnnotationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.healthApi.DeleteAnnotationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.healthApi.HealthProtoServiceClient.prototype.deleteAnnotation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/healthApi.HealthProtoService/DeleteAnnotation',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_DeleteAnnotation,
      callback);
};


/**
 * @param {!proto.healthApi.DeleteAnnotationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.healthApi.DeleteAnnotationResponse>}
 *     Promise that resolves to the response
 */
proto.healthApi.HealthProtoServicePromiseClient.prototype.deleteAnnotation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/healthApi.HealthProtoService/DeleteAnnotation',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_DeleteAnnotation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.healthApi.StartPatientCareRequest,
 *   !proto.healthApi.StartPatientCareResponse>}
 */
const methodDescriptor_HealthProtoService_StartPatientCare = new grpc.web.MethodDescriptor(
  '/healthApi.HealthProtoService/StartPatientCare',
  grpc.web.MethodType.UNARY,
  proto.healthApi.StartPatientCareRequest,
  proto.healthApi.StartPatientCareResponse,
  /**
   * @param {!proto.healthApi.StartPatientCareRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.healthApi.StartPatientCareResponse.deserializeBinary
);


/**
 * @param {!proto.healthApi.StartPatientCareRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.healthApi.StartPatientCareResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.healthApi.StartPatientCareResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.healthApi.HealthProtoServiceClient.prototype.startPatientCare =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/healthApi.HealthProtoService/StartPatientCare',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_StartPatientCare,
      callback);
};


/**
 * @param {!proto.healthApi.StartPatientCareRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.healthApi.StartPatientCareResponse>}
 *     Promise that resolves to the response
 */
proto.healthApi.HealthProtoServicePromiseClient.prototype.startPatientCare =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/healthApi.HealthProtoService/StartPatientCare',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_StartPatientCare);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.healthApi.StopPatientCareRequest,
 *   !proto.healthApi.StopPatientCareResponse>}
 */
const methodDescriptor_HealthProtoService_StopPatientCare = new grpc.web.MethodDescriptor(
  '/healthApi.HealthProtoService/StopPatientCare',
  grpc.web.MethodType.UNARY,
  proto.healthApi.StopPatientCareRequest,
  proto.healthApi.StopPatientCareResponse,
  /**
   * @param {!proto.healthApi.StopPatientCareRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.healthApi.StopPatientCareResponse.deserializeBinary
);


/**
 * @param {!proto.healthApi.StopPatientCareRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.healthApi.StopPatientCareResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.healthApi.StopPatientCareResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.healthApi.HealthProtoServiceClient.prototype.stopPatientCare =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/healthApi.HealthProtoService/StopPatientCare',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_StopPatientCare,
      callback);
};


/**
 * @param {!proto.healthApi.StopPatientCareRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.healthApi.StopPatientCareResponse>}
 *     Promise that resolves to the response
 */
proto.healthApi.HealthProtoServicePromiseClient.prototype.stopPatientCare =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/healthApi.HealthProtoService/StopPatientCare',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_StopPatientCare);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.healthApi.CreateDrugInUseRequest,
 *   !proto.healthApi.CreateDrugInUseResponse>}
 */
const methodDescriptor_HealthProtoService_CreateDrugInUse = new grpc.web.MethodDescriptor(
  '/healthApi.HealthProtoService/CreateDrugInUse',
  grpc.web.MethodType.UNARY,
  proto.healthApi.CreateDrugInUseRequest,
  proto.healthApi.CreateDrugInUseResponse,
  /**
   * @param {!proto.healthApi.CreateDrugInUseRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.healthApi.CreateDrugInUseResponse.deserializeBinary
);


/**
 * @param {!proto.healthApi.CreateDrugInUseRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.healthApi.CreateDrugInUseResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.healthApi.CreateDrugInUseResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.healthApi.HealthProtoServiceClient.prototype.createDrugInUse =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/healthApi.HealthProtoService/CreateDrugInUse',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_CreateDrugInUse,
      callback);
};


/**
 * @param {!proto.healthApi.CreateDrugInUseRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.healthApi.CreateDrugInUseResponse>}
 *     Promise that resolves to the response
 */
proto.healthApi.HealthProtoServicePromiseClient.prototype.createDrugInUse =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/healthApi.HealthProtoService/CreateDrugInUse',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_CreateDrugInUse);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.healthApi.DeleteDrugInUseRequest,
 *   !proto.healthApi.DeleteDrugInUseResponse>}
 */
const methodDescriptor_HealthProtoService_DeleteDrugInUse = new grpc.web.MethodDescriptor(
  '/healthApi.HealthProtoService/DeleteDrugInUse',
  grpc.web.MethodType.UNARY,
  proto.healthApi.DeleteDrugInUseRequest,
  proto.healthApi.DeleteDrugInUseResponse,
  /**
   * @param {!proto.healthApi.DeleteDrugInUseRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.healthApi.DeleteDrugInUseResponse.deserializeBinary
);


/**
 * @param {!proto.healthApi.DeleteDrugInUseRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.healthApi.DeleteDrugInUseResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.healthApi.DeleteDrugInUseResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.healthApi.HealthProtoServiceClient.prototype.deleteDrugInUse =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/healthApi.HealthProtoService/DeleteDrugInUse',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_DeleteDrugInUse,
      callback);
};


/**
 * @param {!proto.healthApi.DeleteDrugInUseRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.healthApi.DeleteDrugInUseResponse>}
 *     Promise that resolves to the response
 */
proto.healthApi.HealthProtoServicePromiseClient.prototype.deleteDrugInUse =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/healthApi.HealthProtoService/DeleteDrugInUse',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_DeleteDrugInUse);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.healthApi.CreateDrinkRequest,
 *   !proto.healthApi.CreateDrinkResponse>}
 */
const methodDescriptor_HealthProtoService_CreateDrink = new grpc.web.MethodDescriptor(
  '/healthApi.HealthProtoService/CreateDrink',
  grpc.web.MethodType.UNARY,
  proto.healthApi.CreateDrinkRequest,
  proto.healthApi.CreateDrinkResponse,
  /**
   * @param {!proto.healthApi.CreateDrinkRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.healthApi.CreateDrinkResponse.deserializeBinary
);


/**
 * @param {!proto.healthApi.CreateDrinkRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.healthApi.CreateDrinkResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.healthApi.CreateDrinkResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.healthApi.HealthProtoServiceClient.prototype.createDrink =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/healthApi.HealthProtoService/CreateDrink',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_CreateDrink,
      callback);
};


/**
 * @param {!proto.healthApi.CreateDrinkRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.healthApi.CreateDrinkResponse>}
 *     Promise that resolves to the response
 */
proto.healthApi.HealthProtoServicePromiseClient.prototype.createDrink =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/healthApi.HealthProtoService/CreateDrink',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_CreateDrink);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.healthApi.UpdateDrinkRequest,
 *   !proto.healthApi.UpdateDrinkResponse>}
 */
const methodDescriptor_HealthProtoService_UpdateDrink = new grpc.web.MethodDescriptor(
  '/healthApi.HealthProtoService/UpdateDrink',
  grpc.web.MethodType.UNARY,
  proto.healthApi.UpdateDrinkRequest,
  proto.healthApi.UpdateDrinkResponse,
  /**
   * @param {!proto.healthApi.UpdateDrinkRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.healthApi.UpdateDrinkResponse.deserializeBinary
);


/**
 * @param {!proto.healthApi.UpdateDrinkRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.healthApi.UpdateDrinkResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.healthApi.UpdateDrinkResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.healthApi.HealthProtoServiceClient.prototype.updateDrink =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/healthApi.HealthProtoService/UpdateDrink',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_UpdateDrink,
      callback);
};


/**
 * @param {!proto.healthApi.UpdateDrinkRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.healthApi.UpdateDrinkResponse>}
 *     Promise that resolves to the response
 */
proto.healthApi.HealthProtoServicePromiseClient.prototype.updateDrink =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/healthApi.HealthProtoService/UpdateDrink',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_UpdateDrink);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.healthApi.DeleteDrinkRequest,
 *   !proto.healthApi.DeleteDrinkResponse>}
 */
const methodDescriptor_HealthProtoService_DeleteDrink = new grpc.web.MethodDescriptor(
  '/healthApi.HealthProtoService/DeleteDrink',
  grpc.web.MethodType.UNARY,
  proto.healthApi.DeleteDrinkRequest,
  proto.healthApi.DeleteDrinkResponse,
  /**
   * @param {!proto.healthApi.DeleteDrinkRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.healthApi.DeleteDrinkResponse.deserializeBinary
);


/**
 * @param {!proto.healthApi.DeleteDrinkRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.healthApi.DeleteDrinkResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.healthApi.DeleteDrinkResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.healthApi.HealthProtoServiceClient.prototype.deleteDrink =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/healthApi.HealthProtoService/DeleteDrink',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_DeleteDrink,
      callback);
};


/**
 * @param {!proto.healthApi.DeleteDrinkRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.healthApi.DeleteDrinkResponse>}
 *     Promise that resolves to the response
 */
proto.healthApi.HealthProtoServicePromiseClient.prototype.deleteDrink =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/healthApi.HealthProtoService/DeleteDrink',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_DeleteDrink);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.healthApi.CreateExerciseRequest,
 *   !proto.healthApi.CreateExerciseResponse>}
 */
const methodDescriptor_HealthProtoService_CreateExercise = new grpc.web.MethodDescriptor(
  '/healthApi.HealthProtoService/CreateExercise',
  grpc.web.MethodType.UNARY,
  proto.healthApi.CreateExerciseRequest,
  proto.healthApi.CreateExerciseResponse,
  /**
   * @param {!proto.healthApi.CreateExerciseRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.healthApi.CreateExerciseResponse.deserializeBinary
);


/**
 * @param {!proto.healthApi.CreateExerciseRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.healthApi.CreateExerciseResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.healthApi.CreateExerciseResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.healthApi.HealthProtoServiceClient.prototype.createExercise =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/healthApi.HealthProtoService/CreateExercise',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_CreateExercise,
      callback);
};


/**
 * @param {!proto.healthApi.CreateExerciseRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.healthApi.CreateExerciseResponse>}
 *     Promise that resolves to the response
 */
proto.healthApi.HealthProtoServicePromiseClient.prototype.createExercise =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/healthApi.HealthProtoService/CreateExercise',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_CreateExercise);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.healthApi.UpdateExerciseRequest,
 *   !proto.healthApi.UpdateExerciseResponse>}
 */
const methodDescriptor_HealthProtoService_UpdateExercise = new grpc.web.MethodDescriptor(
  '/healthApi.HealthProtoService/UpdateExercise',
  grpc.web.MethodType.UNARY,
  proto.healthApi.UpdateExerciseRequest,
  proto.healthApi.UpdateExerciseResponse,
  /**
   * @param {!proto.healthApi.UpdateExerciseRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.healthApi.UpdateExerciseResponse.deserializeBinary
);


/**
 * @param {!proto.healthApi.UpdateExerciseRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.healthApi.UpdateExerciseResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.healthApi.UpdateExerciseResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.healthApi.HealthProtoServiceClient.prototype.updateExercise =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/healthApi.HealthProtoService/UpdateExercise',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_UpdateExercise,
      callback);
};


/**
 * @param {!proto.healthApi.UpdateExerciseRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.healthApi.UpdateExerciseResponse>}
 *     Promise that resolves to the response
 */
proto.healthApi.HealthProtoServicePromiseClient.prototype.updateExercise =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/healthApi.HealthProtoService/UpdateExercise',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_UpdateExercise);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.healthApi.DeleteExerciseRequest,
 *   !proto.healthApi.DeleteExerciseResponse>}
 */
const methodDescriptor_HealthProtoService_DeleteExercise = new grpc.web.MethodDescriptor(
  '/healthApi.HealthProtoService/DeleteExercise',
  grpc.web.MethodType.UNARY,
  proto.healthApi.DeleteExerciseRequest,
  proto.healthApi.DeleteExerciseResponse,
  /**
   * @param {!proto.healthApi.DeleteExerciseRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.healthApi.DeleteExerciseResponse.deserializeBinary
);


/**
 * @param {!proto.healthApi.DeleteExerciseRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.healthApi.DeleteExerciseResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.healthApi.DeleteExerciseResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.healthApi.HealthProtoServiceClient.prototype.deleteExercise =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/healthApi.HealthProtoService/DeleteExercise',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_DeleteExercise,
      callback);
};


/**
 * @param {!proto.healthApi.DeleteExerciseRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.healthApi.DeleteExerciseResponse>}
 *     Promise that resolves to the response
 */
proto.healthApi.HealthProtoServicePromiseClient.prototype.deleteExercise =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/healthApi.HealthProtoService/DeleteExercise',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_DeleteExercise);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.healthApi.CreateCigaretteRequest,
 *   !proto.healthApi.CreateCigaretteResponse>}
 */
const methodDescriptor_HealthProtoService_CreateCigarette = new grpc.web.MethodDescriptor(
  '/healthApi.HealthProtoService/CreateCigarette',
  grpc.web.MethodType.UNARY,
  proto.healthApi.CreateCigaretteRequest,
  proto.healthApi.CreateCigaretteResponse,
  /**
   * @param {!proto.healthApi.CreateCigaretteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.healthApi.CreateCigaretteResponse.deserializeBinary
);


/**
 * @param {!proto.healthApi.CreateCigaretteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.healthApi.CreateCigaretteResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.healthApi.CreateCigaretteResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.healthApi.HealthProtoServiceClient.prototype.createCigarette =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/healthApi.HealthProtoService/CreateCigarette',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_CreateCigarette,
      callback);
};


/**
 * @param {!proto.healthApi.CreateCigaretteRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.healthApi.CreateCigaretteResponse>}
 *     Promise that resolves to the response
 */
proto.healthApi.HealthProtoServicePromiseClient.prototype.createCigarette =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/healthApi.HealthProtoService/CreateCigarette',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_CreateCigarette);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.healthApi.UpdateCigaretteRequest,
 *   !proto.healthApi.UpdateCigaretteResponse>}
 */
const methodDescriptor_HealthProtoService_UpdateCigarette = new grpc.web.MethodDescriptor(
  '/healthApi.HealthProtoService/UpdateCigarette',
  grpc.web.MethodType.UNARY,
  proto.healthApi.UpdateCigaretteRequest,
  proto.healthApi.UpdateCigaretteResponse,
  /**
   * @param {!proto.healthApi.UpdateCigaretteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.healthApi.UpdateCigaretteResponse.deserializeBinary
);


/**
 * @param {!proto.healthApi.UpdateCigaretteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.healthApi.UpdateCigaretteResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.healthApi.UpdateCigaretteResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.healthApi.HealthProtoServiceClient.prototype.updateCigarette =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/healthApi.HealthProtoService/UpdateCigarette',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_UpdateCigarette,
      callback);
};


/**
 * @param {!proto.healthApi.UpdateCigaretteRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.healthApi.UpdateCigaretteResponse>}
 *     Promise that resolves to the response
 */
proto.healthApi.HealthProtoServicePromiseClient.prototype.updateCigarette =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/healthApi.HealthProtoService/UpdateCigarette',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_UpdateCigarette);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.healthApi.DeleteCigaretteRequest,
 *   !proto.healthApi.DeleteCigaretteResponse>}
 */
const methodDescriptor_HealthProtoService_DeleteCigarette = new grpc.web.MethodDescriptor(
  '/healthApi.HealthProtoService/DeleteCigarette',
  grpc.web.MethodType.UNARY,
  proto.healthApi.DeleteCigaretteRequest,
  proto.healthApi.DeleteCigaretteResponse,
  /**
   * @param {!proto.healthApi.DeleteCigaretteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.healthApi.DeleteCigaretteResponse.deserializeBinary
);


/**
 * @param {!proto.healthApi.DeleteCigaretteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.healthApi.DeleteCigaretteResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.healthApi.DeleteCigaretteResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.healthApi.HealthProtoServiceClient.prototype.deleteCigarette =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/healthApi.HealthProtoService/DeleteCigarette',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_DeleteCigarette,
      callback);
};


/**
 * @param {!proto.healthApi.DeleteCigaretteRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.healthApi.DeleteCigaretteResponse>}
 *     Promise that resolves to the response
 */
proto.healthApi.HealthProtoServicePromiseClient.prototype.deleteCigarette =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/healthApi.HealthProtoService/DeleteCigarette',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_DeleteCigarette);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.healthApi.CreateRequestResultRequest,
 *   !proto.healthApi.CreateRequestResultResponse>}
 */
const methodDescriptor_HealthProtoService_CreateRequestResult = new grpc.web.MethodDescriptor(
  '/healthApi.HealthProtoService/CreateRequestResult',
  grpc.web.MethodType.UNARY,
  proto.healthApi.CreateRequestResultRequest,
  proto.healthApi.CreateRequestResultResponse,
  /**
   * @param {!proto.healthApi.CreateRequestResultRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.healthApi.CreateRequestResultResponse.deserializeBinary
);


/**
 * @param {!proto.healthApi.CreateRequestResultRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.healthApi.CreateRequestResultResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.healthApi.CreateRequestResultResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.healthApi.HealthProtoServiceClient.prototype.createRequestResult =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/healthApi.HealthProtoService/CreateRequestResult',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_CreateRequestResult,
      callback);
};


/**
 * @param {!proto.healthApi.CreateRequestResultRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.healthApi.CreateRequestResultResponse>}
 *     Promise that resolves to the response
 */
proto.healthApi.HealthProtoServicePromiseClient.prototype.createRequestResult =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/healthApi.HealthProtoService/CreateRequestResult',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_CreateRequestResult);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.healthApi.DeleteExamRequest,
 *   !proto.healthApi.DeleteExamResponse>}
 */
const methodDescriptor_HealthProtoService_DeleteExam = new grpc.web.MethodDescriptor(
  '/healthApi.HealthProtoService/DeleteExam',
  grpc.web.MethodType.UNARY,
  proto.healthApi.DeleteExamRequest,
  proto.healthApi.DeleteExamResponse,
  /**
   * @param {!proto.healthApi.DeleteExamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.healthApi.DeleteExamResponse.deserializeBinary
);


/**
 * @param {!proto.healthApi.DeleteExamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.healthApi.DeleteExamResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.healthApi.DeleteExamResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.healthApi.HealthProtoServiceClient.prototype.deleteExam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/healthApi.HealthProtoService/DeleteExam',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_DeleteExam,
      callback);
};


/**
 * @param {!proto.healthApi.DeleteExamRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.healthApi.DeleteExamResponse>}
 *     Promise that resolves to the response
 */
proto.healthApi.HealthProtoServicePromiseClient.prototype.deleteExam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/healthApi.HealthProtoService/DeleteExam',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_DeleteExam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.healthApi.UpdateResultExamRequest,
 *   !proto.healthApi.UpdateResultExamResponse>}
 */
const methodDescriptor_HealthProtoService_UpdateResultExam = new grpc.web.MethodDescriptor(
  '/healthApi.HealthProtoService/UpdateResultExam',
  grpc.web.MethodType.UNARY,
  proto.healthApi.UpdateResultExamRequest,
  proto.healthApi.UpdateResultExamResponse,
  /**
   * @param {!proto.healthApi.UpdateResultExamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.healthApi.UpdateResultExamResponse.deserializeBinary
);


/**
 * @param {!proto.healthApi.UpdateResultExamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.healthApi.UpdateResultExamResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.healthApi.UpdateResultExamResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.healthApi.HealthProtoServiceClient.prototype.updateResultExam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/healthApi.HealthProtoService/UpdateResultExam',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_UpdateResultExam,
      callback);
};


/**
 * @param {!proto.healthApi.UpdateResultExamRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.healthApi.UpdateResultExamResponse>}
 *     Promise that resolves to the response
 */
proto.healthApi.HealthProtoServicePromiseClient.prototype.updateResultExam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/healthApi.HealthProtoService/UpdateResultExam',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_UpdateResultExam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.healthApi.DeleteExamResultRequest,
 *   !proto.healthApi.DeleteExamResultResponse>}
 */
const methodDescriptor_HealthProtoService_DeleteExamResult = new grpc.web.MethodDescriptor(
  '/healthApi.HealthProtoService/DeleteExamResult',
  grpc.web.MethodType.UNARY,
  proto.healthApi.DeleteExamResultRequest,
  proto.healthApi.DeleteExamResultResponse,
  /**
   * @param {!proto.healthApi.DeleteExamResultRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.healthApi.DeleteExamResultResponse.deserializeBinary
);


/**
 * @param {!proto.healthApi.DeleteExamResultRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.healthApi.DeleteExamResultResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.healthApi.DeleteExamResultResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.healthApi.HealthProtoServiceClient.prototype.deleteExamResult =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/healthApi.HealthProtoService/DeleteExamResult',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_DeleteExamResult,
      callback);
};


/**
 * @param {!proto.healthApi.DeleteExamResultRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.healthApi.DeleteExamResultResponse>}
 *     Promise that resolves to the response
 */
proto.healthApi.HealthProtoServicePromiseClient.prototype.deleteExamResult =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/healthApi.HealthProtoService/DeleteExamResult',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_DeleteExamResult);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.healthApi.CreateResultExamRequest,
 *   !proto.healthApi.CreateResultExamResponse>}
 */
const methodDescriptor_HealthProtoService_CreateResultExam = new grpc.web.MethodDescriptor(
  '/healthApi.HealthProtoService/CreateResultExam',
  grpc.web.MethodType.UNARY,
  proto.healthApi.CreateResultExamRequest,
  proto.healthApi.CreateResultExamResponse,
  /**
   * @param {!proto.healthApi.CreateResultExamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.healthApi.CreateResultExamResponse.deserializeBinary
);


/**
 * @param {!proto.healthApi.CreateResultExamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.healthApi.CreateResultExamResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.healthApi.CreateResultExamResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.healthApi.HealthProtoServiceClient.prototype.createResultExam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/healthApi.HealthProtoService/CreateResultExam',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_CreateResultExam,
      callback);
};


/**
 * @param {!proto.healthApi.CreateResultExamRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.healthApi.CreateResultExamResponse>}
 *     Promise that resolves to the response
 */
proto.healthApi.HealthProtoServicePromiseClient.prototype.createResultExam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/healthApi.HealthProtoService/CreateResultExam',
      request,
      metadata || {},
      methodDescriptor_HealthProtoService_CreateResultExam);
};


module.exports = proto.healthApi;

