import { HealthProtoServicePromiseClient } from '../grpc/generated/health_grpc_web_pb';

const apiUrl = process.env.VUE_APP_API_URL;
const svc = new HealthProtoServicePromiseClient(apiUrl);
// LoginHealthPatientThroughPlatform

async function listHealthInformation(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.listHealthInformation(request, metadata, {});

    return response.toObject();
}

export default {
    listHealthInformation
}
// export async function listAllergies(request) {
//     const response = await svc.listAllergies(request, metadata, {});
//     return response.toObject();
// }

// export async function addAllergy(request) {
//     const response = await svc.addAllergy(request, metadata, {});
//     return response.toObject();
// }

// export async function deleteAllergy(request) {
//     const response = await svc.deleteAllergy(request, metadata, {});
//     return response.toObject();
// }

// export async function listMeasurements(request) {
//     const response = await svc.listMeasurements(request, metadata, {});
//     return response.toObject();
// }

// export async function addMeasurement(request) {
//     const response = await svc.addMeasurement(request, metadata, {});
//     return response.toObject();
// }

// export async function deleteMeasurement(request) {
//     const response = await svc.deleteMeasurement(request, metadata, {});
//     return response.toObject();
// }

// export async function listImagingExams(request) {
//     const response = await svc.listImagingExams(request, metadata, {});
//     return response.toObject();
// }

// export async function addImagingExam(request) {
//     const response = await svc.addImagingExam(request, metadata, {});
//     return response.toObject();
// }

// export async function listLaboratoryTests(request) {
//     const response = await svc.listLaboratoryTests(request, metadata, {});
//     return response.toObject();
// }

// export async function addLaboratoryTest(request) {
//     const response = await svc.addLaboratoryTest(request, metadata, {});
//     return response.toObject();
// }

// export async function listPhysicalExams(request) {
//     const response = await svc.listPhysicalExams(request, metadata, {});
//     return response.toObject();
// }

// export async function addPhysicalExam(request) {
//     const response = await svc.addPhysicalExam(request, metadata, {});
//     return response.toObject();
// }


// export async function listMedications(request) {
//     const response = await svc.listMedications(request, metadata, {});
//     return response.toObject();
// }

// export async function addMedication(request) {
//     const response = await svc.addMedication(request, metadata, {});
//     return response.toObject();
// }

// export async function listProcedures(request) {
//     const response = await svc.listProcedures(request, metadata, {});
//     return response.toObject();
// }

// export async function addProcedure(request) {
//     const response = await svc.addProcedure(request, metadata, {});
//     return response.toObject();
// }

// export async function listDiagnoses(request) {
//     const response = await svc.listDiagnoses(request, metadata, {});
//     return response.toObject();
// }

// export async function addDiagnosis(request) {
//     const response = await svc.addDiagnosis(request, metadata, {});
//     return response.toObject();
// }

// export async function listReferrals(request) {
//     const response = await svc.listReferrals(request, metadata, {});
//     return response.toObject();
// }

// export async function addReferral(request) {
//     const response = await svc.addReferral(request, metadata, {});
//     return response.toObject();
// }

// export async function listCertificates(request) {
//     const response = await svc.listCertificates(request, metadata, {});
//     return response.toObject();
// }

// export async function addCertificate(request) {
//     const response = await svc.addCertificate(request, metadata, {});
//     return response.toObject();
// }