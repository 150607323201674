<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.67344 13.5609L11.0262 19.4918C11.2898 19.7379 11.6379 19.875 12 19.875C12.3621 19.875 12.7102 19.7379 12.9738 19.4918L19.3266 13.5609C20.3953 12.566 21 11.1703 21 9.71133V9.50743C21 7.05001 19.2246 4.95469 16.8023 4.5504C15.1992 4.28321 13.568 4.80704 12.4219 5.95313L12 6.37501L11.5781 5.95313C10.432 4.80704 8.80078 4.28321 7.19766 4.5504C4.77539 4.95469 3 7.05001 3 9.50743V9.71133C3 11.1703 3.60469 12.566 4.67344 13.5609Z" :fill="fill"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>