import { jwtDecode } from 'jwt-decode';

export function validateToken(token: string): boolean {
  try {
    // Decodifica o token
    const decoded: any = jwtDecode(token);

    // Verifica a expiração do token (se houver)
    if (decoded.exp) {
      const expirationTime = decoded.exp * 1000; // converte para milissegundos
      const currentTime = Date.now();
      if (expirationTime < currentTime) {
        return false; // token expirado
      }
    }

    // Outras verificações de validade podem ser adicionadas aqui, como verificar a assinatura do token

    return true; // token válido
  } catch (error) {
    console.error('Erro ao validar o token:', error);
    return false; // erro ao decodificar o token
  }
}