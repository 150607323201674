import healthService from "@/modules/core/services/healthService"
import healthPb from "@/modules/core/grpc/generated/health_pb";

function valideAuthorization(err) {
    if (err.code === 16) {
        localStorage.clear();
        window.location.href = "/account/login";
    };
}

export async function ListHealthInformation () {
    try {
        const req = new healthPb.EmptyRequest();
        const res = await healthService.listHealthInformation(req);

        // console.log(localStorage.getItem("token_secondary"));

        // console.log(res);

        return res;
    } catch (error) {
        // valideAuthorization(error);
        console.log(error);

        return error;
    }
}
