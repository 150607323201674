<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.875 4.125C19.875 3.67148 19.6008 3.26015 19.1789 3.08437C18.757 2.90859 18.2754 3.00703 17.952 3.32695L16.4191 4.86328C14.7316 6.55078 12.443 7.5 10.0559 7.5H9.75H8.625H5.25C4.00898 7.5 3 8.50898 3 9.75V13.125C3 14.366 4.00898 15.375 5.25 15.375V19.875C5.25 20.4973 5.75273 21 6.375 21H8.625C9.24727 21 9.75 20.4973 9.75 19.875V15.375H10.0559C12.443 15.375 14.7316 16.3242 16.4191 18.0117L17.952 19.5445C18.2754 19.868 18.757 19.9629 19.1789 19.7871C19.6008 19.6113 19.875 19.2035 19.875 18.7465V13.5609C20.5289 13.2516 21 12.4184 21 11.4375C21 10.4566 20.5289 9.62343 19.875 9.31406V4.125ZM17.625 6.82148V11.4375V16.0535C15.5578 14.1727 12.8613 13.125 10.0559 13.125H9.75V9.75H10.0559C12.8613 9.75 15.5578 8.70234 17.625 6.82148Z" :fill="fill"/>
    </svg>

</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>