<template>
    <div class="consultation-future">
        <!-- title -->
        <div class="flex justify-content-between align-items-center">
            <h1>Futuras</h1>
            <div class="flex align-items-center gap-4">
                <div class="btn-drop">
                    <div class="flex align-items-center" style="gap: 4px;">
                        <span>Agendar</span>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.668 7.33334H8.66797V3.33334C8.66797 3.15653 8.59773 2.98696 8.47271 2.86193C8.34768 2.73691 8.17811 2.66667 8.0013 2.66667C7.82449 2.66667 7.65492 2.73691 7.5299 2.86193C7.40487 2.98696 7.33464 3.15653 7.33464 3.33334V7.33334H3.33464C3.15782 7.33334 2.98826 7.40358 2.86323 7.5286C2.73821 7.65362 2.66797 7.82319 2.66797 8C2.66797 8.17682 2.73821 8.34639 2.86323 8.47141C2.98826 8.59643 3.15782 8.66667 3.33464 8.66667H7.33464V12.6667C7.33464 12.8435 7.40487 13.0131 7.5299 13.1381C7.65492 13.2631 7.82449 13.3333 8.0013 13.3333C8.17811 13.3333 8.34768 13.2631 8.47271 13.1381C8.59773 13.0131 8.66797 12.8435 8.66797 12.6667V8.66667H12.668C12.8448 8.66667 13.0143 8.59643 13.1394 8.47141C13.2644 8.34639 13.3346 8.17682 13.3346 8C13.3346 7.82319 13.2644 7.65362 13.1394 7.5286C13.0143 7.40358 12.8448 7.33334 12.668 7.33334Z" fill="#5A5A5A"/>
                        </svg>
                    </div>
                    <div class="divider" />
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.24925 7.9425L8.46675 11.1225C8.53647 11.1928 8.61942 11.2486 8.71081 11.2867C8.80221 11.3247 8.90024 11.3443 8.99925 11.3443C9.09825 11.3443 9.19628 11.3247 9.28768 11.2867C9.37907 11.2486 9.46202 11.1928 9.53175 11.1225L12.7117 7.9425C12.782 7.87277 12.8378 7.78982 12.8759 7.69843C12.914 7.60703 12.9336 7.50901 12.9336 7.41C12.9336 7.31099 12.914 7.21296 12.8759 7.12156C12.8378 7.03017 12.782 6.94722 12.7117 6.8775C12.5712 6.73781 12.3811 6.6594 12.183 6.6594C11.9849 6.6594 11.7948 6.73781 11.6542 6.8775L8.99925 9.5325L6.34425 6.8775C6.20455 6.73894 6.016 6.66082 5.81925 6.66C5.72054 6.65942 5.62269 6.67834 5.53132 6.71567C5.43994 6.75299 5.35683 6.80798 5.28674 6.8775C5.21394 6.94471 5.15517 7.02568 5.11384 7.11574C5.07251 7.2058 5.04944 7.30315 5.04595 7.40218C5.04246 7.5012 5.05864 7.59994 5.09353 7.69268C5.12842 7.78542 5.18135 7.87033 5.24925 7.9425Z" fill="#333333"/>
                    </svg>
                </div>
            </div>
        </div>

        <div class="flex flex-column full-width gap-10">
            <!-- card-default -->
            <div class="default-card">
                <div class="bar-fixed" />
                <div class="flex align-items-center gap-10">
                    <div class="tag" style="background: #5E81FF;">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.9623 6.88272C10.8908 4.39507 8.87525 2.85155 6.70055 2.85155C4.52584 2.85155 2.51025 4.39507 1.43881 6.88272C1.40961 6.94964 1.39453 7.02187 1.39453 7.09489C1.39453 7.1679 1.40961 7.24013 1.43881 7.30705C2.51025 9.79471 4.52584 11.3382 6.70055 11.3382C8.87525 11.3382 10.8908 9.79471 11.9623 7.30705C11.9915 7.24013 12.0066 7.1679 12.0066 7.09489C12.0066 7.02187 11.9915 6.94964 11.9623 6.88272ZM6.70055 10.2774C5.01913 10.2774 3.42788 9.06273 2.51025 7.09489C3.42788 5.12704 5.01913 3.91239 6.70055 3.91239C8.38197 3.91239 9.97322 5.12704 10.8908 7.09489C9.97322 9.06273 8.38197 10.2774 6.70055 10.2774ZM6.70055 4.97322C6.28092 4.97322 5.87072 5.09766 5.52181 5.33079C5.17291 5.56392 4.90097 5.89528 4.74038 6.28296C4.5798 6.67064 4.53778 7.09724 4.61965 7.5088C4.70151 7.92037 4.90358 8.29841 5.2003 8.59513C5.49702 8.89185 5.87507 9.09392 6.28663 9.17579C6.69819 9.25765 7.12479 9.21564 7.51247 9.05505C7.90016 8.89447 8.23152 8.62253 8.46465 8.27362C8.69778 7.92472 8.82221 7.51451 8.82221 7.09489C8.82221 6.53219 8.59868 5.99253 8.20079 5.59464C7.8029 5.19675 7.26325 4.97322 6.70055 4.97322ZM6.70055 8.15572C6.49073 8.15572 6.28563 8.0935 6.11118 7.97694C5.93673 7.86037 5.80076 7.69469 5.72046 7.50085C5.64017 7.30701 5.61916 7.09371 5.6601 6.88793C5.70103 6.68215 5.80206 6.49313 5.95042 6.34477C6.09878 6.19641 6.28781 6.09537 6.49359 6.05444C6.69937 6.01351 6.91267 6.03451 7.10651 6.11481C7.30035 6.1951 7.46603 6.33107 7.5826 6.50552C7.69916 6.67997 7.76138 6.88507 7.76138 7.09489C7.76138 7.37624 7.64961 7.64607 7.45067 7.84501C7.25172 8.04395 6.9819 8.15572 6.70055 8.15572Z" fill="white"/>
                        </svg>
                        <span>Visualizando agora</span>
                    </div>
                    <div class="tag" style="background: #38A8CB;">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.728 2.85176H9.66719V2.32134C9.66719 2.18067 9.6113 2.04575 9.51183 1.94628C9.41236 1.84681 9.27745 1.79092 9.13677 1.79092C8.9961 1.79092 8.86118 1.84681 8.76171 1.94628C8.66224 2.04575 8.60635 2.18067 8.60635 2.32134V2.85176H5.42385V2.32134C5.42385 2.18067 5.36797 2.04575 5.2685 1.94628C5.16903 1.84681 5.03411 1.79092 4.89344 1.79092C4.75276 1.79092 4.61785 1.84681 4.51838 1.94628C4.4189 2.04575 4.36302 2.18067 4.36302 2.32134V2.85176H3.30219C2.88016 2.85176 2.47542 3.01941 2.177 3.31782C1.87859 3.61624 1.71094 4.02098 1.71094 4.44301V10.808C1.71094 11.23 1.87859 11.6348 2.177 11.9332C2.47542 12.2316 2.88016 12.3993 3.30219 12.3993H10.728C11.15 12.3993 11.5548 12.2316 11.8532 11.9332C12.1516 11.6348 12.3193 11.23 12.3193 10.808V4.44301C12.3193 4.02098 12.1516 3.61624 11.8532 3.31782C11.5548 3.01941 11.15 2.85176 10.728 2.85176ZM11.2584 10.808C11.2584 10.9487 11.2026 11.0836 11.1031 11.1831C11.0036 11.2825 10.8687 11.3384 10.728 11.3384H3.30219C3.16151 11.3384 3.0266 11.2825 2.92713 11.1831C2.82765 11.0836 2.77177 10.9487 2.77177 10.808V7.09509H11.2584V10.808ZM11.2584 6.03426H2.77177V4.44301C2.77177 4.30233 2.82765 4.16742 2.92713 4.06795C3.0266 3.96847 3.16151 3.91259 3.30219 3.91259H4.36302V4.44301C4.36302 4.58368 4.4189 4.7186 4.51838 4.81807C4.61785 4.91754 4.75276 4.97342 4.89344 4.97342C5.03411 4.97342 5.16903 4.91754 5.2685 4.81807C5.36797 4.7186 5.42385 4.58368 5.42385 4.44301V3.91259H8.60635V4.44301C8.60635 4.58368 8.66224 4.7186 8.76171 4.81807C8.86118 4.91754 8.9961 4.97342 9.13677 4.97342C9.27745 4.97342 9.41236 4.91754 9.51183 4.81807C9.6113 4.7186 9.66719 4.58368 9.66719 4.44301V3.91259H10.728C10.8687 3.91259 11.0036 3.96847 11.1031 4.06795C11.2026 4.16742 11.2584 4.30233 11.2584 4.44301V6.03426Z" fill="white"/>
                        </svg>
                        <span>Agendado</span>
                    </div>
                    <span class="date-exam">
                        06 DE FEVEREIRO 2024
                    </span>
                </div>
                <div class="description flex align-items center mt-10">
                    Consulta
                </div>
                <div class="sub-group">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt...
                </div>

                <Divider style="margin-top: 14px;margin-bottom: 20px;"/>
                <div class="flex align-items-center justify-content-between">
                    <div class="flex align-items-center gap-8">
                        <img class="profile" src="@/assets/avatares/professionals/1.png" alt="" />
                        <div class="flex flex-column">
                            <span class="name-professional">Carmem Santos</span>
                            <span class="location">Florianópolis, Santa Catarina</span>
                            <span class="speciality">Psicóloga</span>
                        </div>
                    </div>

                    <button class="options">
                        Av Frederico Augusto Ritter, N° 5543
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.90703 1.8823C6.59421 1.8823 5.33516 2.40381 4.40685 3.33212C3.47855 4.26042 2.95703 5.51947 2.95703 6.8323C2.95703 10.1735 7.31922 13.9479 7.50484 14.1088C7.61692 14.2047 7.75955 14.2573 7.90703 14.2573C8.05451 14.2573 8.19714 14.2047 8.30922 14.1088C8.52578 13.9479 12.857 10.1735 12.857 6.8323C12.857 5.51947 12.3355 4.26042 11.4072 3.33212C10.4789 2.40381 9.21985 1.8823 7.90703 1.8823ZM7.90703 12.8032C6.58909 11.5657 4.19453 8.89892 4.19453 6.8323C4.19453 5.84768 4.58567 4.90339 5.2819 4.20716C5.97813 3.51093 6.92241 3.1198 7.90703 3.1198C8.89165 3.1198 9.83594 3.51093 10.5322 4.20716C11.2284 4.90339 11.6195 5.84768 11.6195 6.8323C11.6195 8.89892 9.22497 11.5719 7.90703 12.8032ZM7.90703 4.3573C7.41752 4.3573 6.93901 4.50245 6.532 4.77441C6.12498 5.04636 5.80776 5.43291 5.62043 5.88515C5.4331 6.3374 5.38409 6.83504 5.47959 7.31514C5.57509 7.79525 5.81081 8.23625 6.15694 8.58238C6.50308 8.92852 6.94408 9.16424 7.42418 9.25974C7.90429 9.35524 8.40193 9.30622 8.85417 9.1189C9.30642 8.93157 9.69296 8.61434 9.96492 8.20733C10.2369 7.80032 10.382 7.3218 10.382 6.8323C10.382 6.17588 10.1213 5.54636 9.65712 5.08221C9.19297 4.61805 8.56344 4.3573 7.90703 4.3573ZM7.90703 8.0698C7.66228 8.0698 7.42302 7.99722 7.21951 7.86124C7.01601 7.72526 6.85739 7.53199 6.76373 7.30587C6.67007 7.07974 6.64556 6.83092 6.69331 6.59087C6.74106 6.35082 6.85892 6.13032 7.03199 5.95725C7.20505 5.78418 7.42556 5.66632 7.66561 5.61857C7.90566 5.57082 8.15448 5.59533 8.3806 5.68899C8.60673 5.78266 8.8 5.94127 8.93597 6.14478C9.07195 6.34828 9.14453 6.58754 9.14453 6.8323C9.14453 7.1605 9.01415 7.47526 8.78208 7.70734C8.55 7.93942 8.23524 8.0698 7.90703 8.0698Z" fill="#333333"/>
                        </svg>
                    </button>
                </div>
            </div>
            <!-- card secondary -->
            <div class="secondary-card" v-for="x in 3">
                <div class="bar-fixed" />
                <div class="flex align-items-center gap-10">
                    <div class="tag" style="background: #38A8CB;">
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.5 2H8.5V1.5C8.5 1.36739 8.44732 1.24021 8.35355 1.14645C8.25979 1.05268 8.13261 1 8 1C7.86739 1 7.74021 1.05268 7.64645 1.14645C7.55268 1.24021 7.5 1.36739 7.5 1.5V2H4.5V1.5C4.5 1.36739 4.44732 1.24021 4.35355 1.14645C4.25979 1.05268 4.13261 1 4 1C3.86739 1 3.74021 1.05268 3.64645 1.14645C3.55268 1.24021 3.5 1.36739 3.5 1.5V2H2.5C2.10218 2 1.72064 2.15804 1.43934 2.43934C1.15804 2.72064 1 3.10218 1 3.5V9.5C1 9.89782 1.15804 10.2794 1.43934 10.5607C1.72064 10.842 2.10218 11 2.5 11H9.5C9.89782 11 10.2794 10.842 10.5607 10.5607C10.842 10.2794 11 9.89782 11 9.5V3.5C11 3.10218 10.842 2.72064 10.5607 2.43934C10.2794 2.15804 9.89782 2 9.5 2ZM10 9.5C10 9.63261 9.94732 9.75979 9.85355 9.85355C9.75979 9.94732 9.63261 10 9.5 10H2.5C2.36739 10 2.24021 9.94732 2.14645 9.85355C2.05268 9.75979 2 9.63261 2 9.5V6H10V9.5ZM10 5H2V3.5C2 3.36739 2.05268 3.24021 2.14645 3.14645C2.24021 3.05268 2.36739 3 2.5 3H3.5V3.5C3.5 3.63261 3.55268 3.75979 3.64645 3.85355C3.74021 3.94732 3.86739 4 4 4C4.13261 4 4.25979 3.94732 4.35355 3.85355C4.44732 3.75979 4.5 3.63261 4.5 3.5V3H7.5V3.5C7.5 3.63261 7.55268 3.75979 7.64645 3.85355C7.74021 3.94732 7.86739 4 8 4C8.13261 4 8.25979 3.94732 8.35355 3.85355C8.44732 3.75979 8.5 3.63261 8.5 3.5V3H9.5C9.63261 3 9.75979 3.05268 9.85355 3.14645C9.94732 3.24021 10 3.36739 10 3.5V5Z" fill="white"/>
                        </svg>
                        <span>Agendado</span>
                    </div>
                    <span class="date-exam">
                        06 DE FEVEREIRO 2024
                    </span>   
                </div>
                <div class="description flex align-items center mt-10">
                    Consulta
                </div>
                <Divider style="margin: 14px 0;" />

                <div class="flex align-items-center justify-content-between">
                    <div class="flex flex-column">
                        <span class="name-professional">André Ribeiro Pinheiros</span>
                        <span class="speciality">Clinico geral</span>
                    </div>
                    <div class="flex align-items-center gap-8">
                        <button class="options">
                            Rua Martin Flores, N° 21
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.9987 1.16667C5.76102 1.16667 4.57404 1.65834 3.69887 2.53351C2.8237 3.40868 2.33203 4.59566 2.33203 5.83334C2.33203 8.98334 6.44453 12.5417 6.61953 12.6933C6.72519 12.7837 6.85966 12.8334 6.9987 12.8334C7.13774 12.8334 7.2722 12.7837 7.37786 12.6933C7.58203 12.5417 11.6654 8.98334 11.6654 5.83334C11.6654 4.59566 11.1737 3.40868 10.2985 2.53351C9.42336 1.65834 8.23638 1.16667 6.9987 1.16667ZM6.9987 11.4625C5.7562 10.2958 3.4987 7.78167 3.4987 5.83334C3.4987 4.90508 3.86745 4.01484 4.52382 3.35846C5.1802 2.70209 6.07044 2.33334 6.9987 2.33334C7.92696 2.33334 8.8172 2.70209 9.47357 3.35846C10.13 4.01484 10.4987 4.90508 10.4987 5.83334C10.4987 7.78167 8.2412 10.3017 6.9987 11.4625ZM6.9987 3.50001C6.53721 3.50001 6.08608 3.63685 5.70237 3.89324C5.31865 4.14963 5.01958 4.51405 4.84298 4.94041C4.66637 5.36677 4.62017 5.83593 4.7102 6.28855C4.80023 6.74117 5.02246 7.15693 5.34878 7.48325C5.6751 7.80958 6.09087 8.03181 6.54349 8.12184C6.99611 8.21187 7.46527 8.16566 7.89163 7.98906C8.31799 7.81245 8.6824 7.51338 8.93879 7.12967C9.19518 6.74595 9.33203 6.29483 9.33203 5.83334C9.33203 5.2145 9.0862 4.62101 8.64861 4.18342C8.21103 3.74584 7.61754 3.50001 6.9987 3.50001ZM6.9987 7C6.76795 7 6.54239 6.93158 6.35053 6.80339C6.15868 6.67519 6.00914 6.49298 5.92084 6.2798C5.83254 6.06662 5.80943 5.83204 5.85445 5.60573C5.89946 5.37942 6.01058 5.17154 6.17374 5.00838C6.3369 4.84522 6.54478 4.7341 6.77109 4.68909C6.9974 4.64407 7.23198 4.66718 7.44516 4.75548C7.65834 4.84378 7.84055 4.99332 7.96875 5.18517C8.09694 5.37703 8.16537 5.60259 8.16537 5.83334C8.16537 6.14276 8.04245 6.4395 7.82366 6.6583C7.60486 6.87709 7.30812 7 6.9987 7Z" fill="#333333"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>

</script>

<style lang="scss" scoped>
.consultation-future {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    font-family: "Roboto", sans-serif;

    .divider {
        width: 1px;
        height: 28px;
        background: #EFEFEF;
    }

    .btn-drop {
        display: inline-flex;
        padding: 0px 10px 0px 12px;
        justify-content: center;
        align-items: center;
        gap: 6px;

        color: #5A5A5A;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        border-radius: 128.571px;
        background: #FFF;

        /* Shadow Cards */
        box-shadow: 0px 8px 24px 0px rgba(61, 71, 102, 0.08), 0px 3px 6px 0px rgba(61, 71, 102, 0.06);
    }
    
    .bar-fixed {
        width: 4px;
        height: 40px;
        background: #007DA5;
        border-radius: 100px !important;
        position: absolute;
        left: -2px;
        // deixe centralizado na vertical
        top: 50%;
        transform: translateY(-50%);
    }
    .default-card {
        position: relative;
        width: 100%;
        padding: 10px 16px;
        border-radius: 21px;
        background: var(--Off-Ligh-2, #FDFDFD);
        box-shadow: 0px 8.486px 25.459px 0px rgba(61, 71, 102, 0.20), 0px 3.182px 6.365px 0px rgba(61, 71, 102, 0.06);
    
        display: flex;
        flex-direction: column;
    }
    .secondary-card {
        position: relative;
        width: 96%;
        padding: 10px 16px;
        border-radius: 21px;
        background: var(--Off-Ligh-2, #FDFDFD);
        box-shadow: 0px 8.486px 25.459px 0px rgba(61, 71, 102, 0.20), 0px 3.182px 6.365px 0px rgba(61, 71, 102, 0.06);
        opacity: 0.5;
        margin: 0 auto;
    
        display: flex;
        flex-direction: column;
    }
    .tag {
        display: flex;
        padding: 2px 8px 2px 4px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 136px;

        color: var(--White, #FFF);
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .date-exam {
        color: var(--Gray-3, #828282);
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .description {
        color: var(--Gray-2, #4F4F4F);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .sub-group{
        color: var(--Gray-3, #828282);
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .profile{
        width: 36px;
        height: 36px;
        border-radius: 106px;
    }
    .description {
        color: #727272;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .location {
        color: var(--Gray-3, #828282);
        font-size: 10.608px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .speciality {
        color: var(--Gray-3, #828282);
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .options{
        display: flex;
        padding: 6px 10px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border: none;

        color: #4F4F4F;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        border-radius: 136.389px;
        background: #F2F2F2;

        cursor: pointer;
    }
}
:deep(){
    h1 {
        margin: 0;
        color: #333;
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }    
    .p-divider {
        margin: 4px 0;
        &:before{
            border-color: #F2F2F2 !important;
        }
    }
}
</style>