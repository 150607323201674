<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
        <path d="M9.17 6.99999C8.98375 7.18735 8.87921 7.44081 8.87921 7.70499C8.87921 7.96918 8.98375 8.22263 9.17 8.40999L12.71 12L9.17 15.54C8.98375 15.7274 8.87921 15.9808 8.87921 16.245C8.87921 16.5092 8.98375 16.7626 9.17 16.95C9.26297 17.0437 9.37357 17.1181 9.49543 17.1689C9.61729 17.2197 9.74799 17.2458 9.88 17.2458C10.012 17.2458 10.1427 17.2197 10.2646 17.1689C10.3864 17.1181 10.497 17.0437 10.59 16.95L14.83 12.71C14.9237 12.617 14.9981 12.5064 15.0489 12.3846C15.0997 12.2627 15.1258 12.132 15.1258 12C15.1258 11.868 15.0997 11.7373 15.0489 11.6154C14.9981 11.4936 14.9237 11.383 14.83 11.29L10.59 6.99999C10.497 6.90626 10.3864 6.83187 10.2646 6.7811C10.1427 6.73033 10.012 6.70419 9.88 6.70419C9.74799 6.70419 9.61729 6.73033 9.49543 6.7811C9.37357 6.83187 9.26297 6.90626 9.17 6.99999Z" :fill="fill"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>